export const DownArrow = () => {
  return (
    <svg
      className="down-arrow"
      width="16px"
      viewBox="0 0 16 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.46339 0.322678V32.5M8.46339 32.5L1.10394 25.6856M8.46339 32.5L14.896 25.6856"
        strokeWidth="1.75"
        stroke="white"
      />
    </svg>
  );
};

export const MinimiseArrow = () => {
  return (
    <svg
      width="14px"
      viewBox="0 0 45 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4635 0.322754V32.5001M21.4635 32.5001L6.78279 19.1877M21.4635 32.5001L35.33 19.1877M21.4635 32.5001H44.1138M21.4635 32.5001H0.072998"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
