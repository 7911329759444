import { shaderMaterial } from "@react-three/drei";

export const RingShader = new shaderMaterial(
  //uniforms -  if none; include an empty object!
  {
    uDiffuse: null,
  },
  // vertex shader vNormal = normalize( normalMatrix * normal );
  /* glsl */ `

    #include <common>
    #include <fog_pars_vertex>
    #include <shadowmap_pars_vertex>
    varying vec2 vUV;

    void main() {
      #include <begin_vertex>
      #include <project_vertex>
      #include <worldpos_vertex>
      #include <shadowmap_vertex>
      #include <fog_vertex>

    vUV = uv * 2.0 - 1.0;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,
  // fragment shader
  /* glsl */ `
    #include <common>
    #include <packing>
    #include <fog_pars_fragment>
    #include <bsdfs>
    #include <lights_pars_begin>
    #include <shadowmap_pars_fragment>
    #include <shadowmask_pars_fragment>
    #include <dithering_pars_fragment>
    
    uniform sampler2D uDiffuse;

    varying vec2 vUV;
     varying vec3 cameraViewPos;
    
    // normalize and center uv


    void main() {
      vec3 shadowColor = vec3(0, 0, 0);
      float shadowPower = 0.5;
    // calculate polar r coord and saturate it
    float r = length(vUV);

    // constant to determine the size of central gap and inner circle radius
    const float center = 0.4;

    // here we're calculating an angle to simulate a rotation
    float angle = atan(vUV.y, vUV.x);

    float vlength = (r - center) / (1.0 - center);

    // Output to screen from 2D texture. U coord is animated and
    vec4 col = texture2D(uDiffuse, vec2((sin(angle) + 1.0) / (512.0 * 0.1), vlength)) * step(0.01, vlength) * step(vlength, 0.99);

    gl_FragColor.rgb = mix(col.xyz, shadowColor, (1.0 - getShadowMask() ) * shadowPower);
    gl_FragColor.a = col.w;

    #include <dithering_fragment>
    }`
);
export const Atmosphere = new shaderMaterial(
  //uniforms -  if none; include an empty object!
  {},
  // vertex shader vNormal = normalize( normalMatrix * normal );
  /* glsl */ `
  #include <fog_vertex>
  varying vec3 vPositionW;
  varying vec3 vNormalW;
  varying vec3 vEyeNormal;
  varying mat4 LM;
  

    void main() {
   
      vPositionW =  normalize(vec3(modelViewMatrix * vec4(position, 1.0)).xyz);
      vNormalW = normalize(normalMatrix * normal);
      vEyeNormal = normalize(vNormalW - cameraPosition);
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,
  // fragment shader
  /* glsl */ `
  
  varying vec3 vPositionW;
  varying vec3 vNormalW;
  varying vec3 vEyeNormal;

    void main() {
      vec3 viewDirectionW = normalize(cameraPosition - vPositionW);
      float fresnel = ( 1.0 - -min(dot(vPositionW, normalize(vNormalW) ), 0.0) );
      fresnel = clamp(pow(fresnel, 8.0), 0.0, 1.0);
      vec3 skyColor = vec3(0.4,0.6,1.0);
      gl_FragColor.rgb += skyColor;
      gl_FragColor.a += fresnel;

    }`
);
