import { DownArrow } from "./icons";

export const ViewSelection = ({
  controls,
  setControls,
  objects,
  cameraController,
}) => {
  return (
    <>
      <div className="view">
        <input
          className={`button ${
            controls.camera === objects.name ? "active" : ""
          }`}
          type="button"
          name="move"
          value="reset"
          onClick={(e) => {
            cameraController.current.setLookAt(0, 120, 400, 0, 0, 0, true);
            setControls(e.target.value, "camera");
          }}
        />
        {objects.map((object) => {
          return (
            <input
              className={`button ${
                controls.camera === object.name ? "active" : ""
              }`}
              key={object.name}
              type="button"
              name="move"
              value={object.name}
              onClick={(e) => {
                setControls(e.target.value, "camera");
              }}
            />
          );
        })}
      </div>
      <p style={{ marginTop: "12px" }}>
        You can use the keyboard/mouse to look/move around. Mouse press and drag
        to look around, scroll to zoom in/out. Keyboard keys &#8592;, &#8594; or
        A and S will pan left and right. &#8593;, &#8595; or W and S will pan
        you forwards and backwards.
      </p>
    </>
  );
};

export const Manipulators = ({ controls, setControls }) => {
  return (
    <div className="manipulators">
      <div className="scale">
        <input
          type="range"
          name="scale"
          min="1.0"
          max="10.0"
          step="0.1"
          defaultValue="2.45"
          onChange={(event) => {
            setControls(event.target.value, "scale");
          }}
        />
        <label>Scale: x{controls.scale}</label>
      </div>
      <div className="scale" style={{ gap: "6px" }}>
        <input
          type="checkbox"
          name="lines"
          defaultChecked
          onChange={() => {
            setControls(!controls.lines, "lines");
          }}
        />
        <label>Lines</label>
        <input
          type="checkbox"
          name="labels"
          defaultChecked
          onChange={() => {
            setControls(!controls.labels, "labels");
          }}
        />
        <label>Labels</label>
        <input
          type="checkbox"
          name="track"
          defaultChecked
          onChange={() => {
            setControls(!controls.track, "track");
          }}
        />
        <label>Track Object</label>
      </div>
    </div>
  );
};

export const Intro = () => {
  const date = new Date();

  return (
    <div className="bottom-slider">
      <div>
        <h5>One Astronomic Unit</h5>
        <p>
          ...is the distance from the Earth to the sun. Astronomers and
          scientists use this to measure the distance to all the other planets.
          This website is an attempt to show you the true scale of your solar
          system. The orbits are calculated from Kepler's formulas for
          elliptical orbits. All the data is from &nbsp;
          <a
            href="https://nssdc.gsfc.nasa.gov/planetary/factsheet/"
            target="_blank"
            rel="noreferrer"
          >
            NSDCC / NASA planetary factsheets.
          </a>
          &nbsp; You can click on a planet, select it from the menu, or move
          around the solar system with your mouse, keyboard, finger, or the
          controls provided. You will notice the scale. All the planets (when
          you start) are 5 times bigger than they should be, otherwise you will
          find it very hard to find them; in the vastness of space!
          <br />
          <br />
          We hope you enjoy this website and learn something new about your
          home. We are open to feature requests from educaters and users,
          contact us{" "}
          <a
            href="mailto: info@cornellbox.studio"
            target="_blank"
            rel="noreferrer"
          >
            here.
          </a>{" "}
          We are also open to bug reports from fellow developers, or if you are
          an astrophysicist and find a problem with the method, please do get in
          touch.
          <br />
          <br />
          This site uses cookies, only to measure how many people visit our site
          and for how long they use it. By using the site, you agree to allow us
          to track your usage, whilst on this site. If you do not click on or
          interact with site, no cookies will be stored on your browser. All
          rights reserved. ©Cornell Box Ltd {date.getYear() + 1900}.
        </p>
      </div>
      <div>
        <DownArrow />
      </div>
    </div>
  );
};
