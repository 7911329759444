import { Html, Line, useTexture } from "@react-three/drei";
import { extend, useFrame } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { DoubleSide, MathUtils } from "three";
import { Atmosphere, RingShader } from "../data/shaders";
import { useOrbit } from "./hooks/hooks";

extend({ RingShader, Atmosphere });

export const Ellipse = (props) => {
  const {
    GLOBAL_ROTATION_RATE,
    GLOBAL_SCALE_MULTIPLIER,
    object,
    cameraController,
    controls,
  } = {
    ...props,
  };
  const ref = useRef();
  const motionRef = useRef();
  const planetRef = useRef();
  const moonRef = useRef();

  const {
    distance,
    name,
    eccentricity,
    rotation,
    obliquity,
    rate,
    offset,
    radius,
    inclination,
    texture,
    ring,
    ring_start,
    ring_end,
    ring_texture,
    moon_texture,
    moon_distance,
    moon_eccentricity,
    moon_rate,
    moon_radius,
  } = object;

  const map = useTexture(`/textures/${texture}`);
  const ringTex = useTexture(
    ring_texture ? `/textures/${ring_texture}` : `/textures/${texture}`
  );
  const moonTex = useTexture(
    moon_texture ? `/textures/${moon_texture}` : `/textures/${texture}`
  );
  const clouds = useTexture("/textures/2k_earth_cloudmap.jpg");

  useEffect(() => {
    //adjust the position to centre on the foci - Sun
    ref.current.position.z = -distance * eccentricity;
  }, [distance, eccentricity]);

  useEffect(() => {
    if (cameraController.current)
      cameraController.current.setLookAt(0, 120, 400, 0, 0, 0, true);
  }, [cameraController]);

  useEffect(() => {
    if (controls.camera === name) {
      cameraController.current.setPosition(14, 22, 12, true);
      cameraController.current.fitToSphere(planetRef.current, true);
    }
  }, [name, cameraController, controls.camera, controls.track]);

  const orbit = useOrbit(distance, eccentricity, GLOBAL_SCALE_MULTIPLIER);

  const moon_orbit = useOrbit(
    moon_distance,
    moon_eccentricity,
    GLOBAL_SCALE_MULTIPLIER
  );

  useFrame((state) => {
    //a suitably huge number of seconds!
    const year =
      ((9999999 + state.clock.elapsedTime / rate / 100 + offset) /
        GLOBAL_SCALE_MULTIPLIER) %
      1;

    const p = orbit.getPoint(Math.abs(year));
    //set the position of the planet
    motionRef.current.position.set(p.x, 0, p.z);

    if (name === "earth" && moonRef.current) {
      const moon_year =
        ((9999999 + state.clock.elapsedTime / moon_rate / 100) /
          GLOBAL_SCALE_MULTIPLIER) %
        1;

      const moon_p = moon_orbit.getPoint(Math.abs(moon_year));
      //get the point on the orbit corresponding to the time of year + offset

      moonRef.current.position.set(
        moon_p.x * Math.pow(controls.scale, 2),
        0,
        moon_p.z * Math.pow(controls.scale, 2)
      );
    }

    planetRef.current.rotation.y += (36.5 / rotation) * GLOBAL_ROTATION_RATE;

    // //if the camera is set to this planet move the default camera here...
    if (controls.camera === name && controls.track) {
      cameraController.current.setPosition(12, 18, 12, true);
      cameraController.current.fitToSphere(planetRef.current.children[0], true);
      cameraController.current.setFocalOffset(
        name === "venus" ? -2 : 2,
        0,
        0,
        true
      );
    }
  });

  return (
    <group ref={ref} rotation={[0, 0, MathUtils.degToRad(inclination)]}>
      <group
        ref={motionRef}
        name="planet-group"
        rotation={[MathUtils.degToRad(obliquity), 0, 0]}
      >
        {controls.labels && (
          <Html wrapperClass="planet-labels">
            <p>{name}</p>
          </Html>
        )}
        <group ref={planetRef}>
          <mesh
            receiveShadow
            castShadow
            name="planet"
            scale={Math.pow(controls.scale, 3)}
          >
            <sphereGeometry args={[radius * GLOBAL_SCALE_MULTIPLIER, 64, 64]} />
            <meshStandardMaterial map={map} />
            {name === "earth" && (
              <>
                <mesh name="clouds">
                  <sphereGeometry
                    args={[radius * GLOBAL_SCALE_MULTIPLIER, 64, 64]}
                  />
                  <meshStandardMaterial
                    color={"white"}
                    alphaMap={clouds}
                    alphaTest={0.1}
                    transparent
                  />
                </mesh>
                <mesh name="atmosphere">
                  <sphereGeometry
                    args={[radius * GLOBAL_SCALE_MULTIPLIER, 64, 64]}
                  />
                  <atmosphere transparent toneMapped={false} />
                </mesh>
              </>
            )}
          </mesh>
          {ring && (
            <mesh
              rotation={[Math.PI / 2, 0, 0]}
              receiveShadow
              name="ring"
              scale={Math.pow(controls.scale, 3)}
            >
              <ringGeometry
                args={[
                  ring_start * GLOBAL_SCALE_MULTIPLIER,
                  ring_end * GLOBAL_SCALE_MULTIPLIER,
                  120,
                  1,
                ]}
              />
              <ringShader uDiffuse={ringTex} side={DoubleSide} transparent />
            </mesh>
          )}
        </group>
        {name === "earth" && (
          <mesh
            name="moon"
            ref={moonRef}
            receiveShadow
            castShadow
            scale={Math.pow(controls.scale, 3)}
          >
            <sphereGeometry
              args={[moon_radius * GLOBAL_SCALE_MULTIPLIER, 64, 64]}
            />
            <meshStandardMaterial map={moonTex} />
          </mesh>
        )}
        {name === "earth" && controls.lines && (
          <Line
            scale={Math.pow(controls.scale, 2)}
            points={moon_orbit.getPoints(120)}
            color={"white"}
            lineWidth={0.05}
          />
        )}
      </group>

      <Line
        visible={
          controls.camera === name && controls.lines
            ? 1
            : controls.camera === "reset" && controls.lines
            ? 1
            : !controls.track && controls.lines
            ? 1
            : 0
        }
        dashed
        points={orbit.getPoints(120)}
        color={"white"}
        lineWidth={0.1}
      />
    </group>
  );
};
