import { CameraControls, Loader, useTexture } from "@react-three/drei";
import "./App.css";
import { Canvas, useFrame } from "@react-three/fiber";
import { Ellipse } from "./components/ellipse";
import { objects } from "./data/objects";
import { Suspense, useEffect, useRef, useState } from "react";
import { BackSide, MathUtils } from "three";
import {
  Bloom,
  EffectComposer,
  Noise,
  Vignette,
} from "@react-three/postprocessing";
import { Asteroids } from "./components/asteroids";
import { PopUps } from "./components/popups";
import { Intro, Manipulators, ViewSelection } from "./components/UI";
import { MinimiseArrow } from "./components/icons";

const GLOBAL_ROTATION_RATE = 0.01;
//this is equivalent to the unit scale of three.js going from cm to m, etc.
const GLOBAL_SCALE_MULTIPLIER = 1;

const Scene = ({ controls, setControls, cameraController, nav }) => {
  const [hovered, setHovered] = useState(false);
  const keyRef = useRef(new Set());

  const stars = useTexture("/textures/8k_stars_milky_way.jpg");
  const sun = useTexture("/textures/2k_sun.jpg");

  useEffect(() => {
    const onKeyDown = (e) => keyRef.current.add(e.code);
    const onKeyUp = (e) => keyRef.current.delete(e.code);
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  useFrame((delta) => {
    if (keyRef.current.has("KeyW") || keyRef.current.has("ArrowUp")) {
      cameraController.current.dollyInFixed(10, true);
    } else if (keyRef.current.has("KeyS") || keyRef.current.has("ArrowDown")) {
      cameraController.current.dollyInFixed(-10, true);
    } else if (keyRef.current.has("KeyA") || keyRef.current.has("ArrowLeft")) {
      cameraController.current.truck(-10, 0, true);
    } else if (keyRef.current.has("KeyD") || keyRef.current.has("ArrowRight")) {
      cameraController.current.truck(10, 0, true);
    }
  });

  return (
    <group>
      <mesh name="background" scale={100000 * GLOBAL_SCALE_MULTIPLIER}>
        <sphereGeometry />
        <meshBasicMaterial side={BackSide} map={stars} />
      </mesh>
      <mesh
        userData={{ lensflare: "no-occlusion" }}
        name="sun"
        scale={MathUtils.lerp(1, 3, controls.scale)}
      >
        <sphereGeometry args={[0.696 * GLOBAL_SCALE_MULTIPLIER]} />
        <meshStandardMaterial
          map={sun}
          emissiveMap={sun}
          emissive={[1, 1, 1]}
          emissiveIntensity={18}
          toneMapped={false}
        />
      </mesh>
      <pointLight intensity={4} decay={0.001} castShadow></pointLight>
      {objects.map((object) => {
        return (
          <group
            onPointerOver={() => setHovered(true)}
            onPointerOut={() => setHovered(false)}
            key={object.name}
            onClick={() => setControls(object.name, "camera")}
          >
            {object.radius ? (
              <Ellipse
                GLOBAL_ROTATION_RATE={GLOBAL_ROTATION_RATE}
                GLOBAL_SCALE_MULTIPLIER={GLOBAL_SCALE_MULTIPLIER}
                controls={controls}
                object={object}
                cameraController={cameraController}
              />
            ) : (
              <Asteroids
                object={object}
                controls={controls}
                cameraController={cameraController}
                GLOBAL_SCALE_MULTIPLIER={GLOBAL_SCALE_MULTIPLIER}
              />
            )}
            <PopUps object={object} camera={controls.camera} nav={nav} />
          </group>
        );
      })}
      <EffectComposer multisampling={24}>
        <Noise opacity={0.025} />
        <Vignette eskil={false} offset={0.1} darkness={0.9} />
        <Bloom mipmapBlur luminanceThreshold={1.84} levels={6} intensity={7} />
      </EffectComposer>
    </group>
  );
};

function App() {
  const [menu, setMenu] = useState("menu--info");
  const cameraController = useRef();
  const nav = useRef();
  const [controls, setValue] = useState({
    scale: 5,
    lines: true,
    labels: true,
    track: true,
    camera: "reset",
  });

  function setControls(value, control) {
    switch (control) {
      case "camera":
        setValue({ ...controls, camera: value });
        break;
      case "labels":
        setValue({ ...controls, labels: value });
        break;
      case "lines":
        setValue({ ...controls, lines: value });
        break;
      case "scale":
        setValue({ ...controls, scale: value });
        break;
      case "track":
        setValue({ ...controls, track: value });
        break;
      default:
        break;
    }
  }

  return (
    <div className="App">
      <nav ref={nav}>
        <button
          onClick={() => {
            cameraController.current.setLookAt(0, 120, 400, 0, 0, 0, true);
            setControls("reset", "camera");
          }}
          className="empty-button"
        >
          <div className="border-box">
            <h1>Au</h1>
          </div>
        </button>
        <h1 className="title">Astronomic Units</h1>
      </nav>
      <Canvas
        camera={{
          near: 0.01,
          far: 10000000,
          fov: 89,
        }}
      >
        <Suspense>
          <CameraControls
            ref={cameraController}
            maxDistance={10000 * GLOBAL_SCALE_MULTIPLIER}
            minDistance={5 * GLOBAL_SCALE_MULTIPLIER}
          />
          <Scene
            nav={nav}
            controls={controls}
            setControls={setControls}
            cameraController={cameraController}
          />
        </Suspense>
      </Canvas>
      <Loader />
      <footer className={`${menu === "menu--minimise" ? "hide" : "visible"}`}>
        <div className="menu-tabs">
          <button
            className={`button ${menu === "menu--info" ? "active" : ""}`}
            onClick={() => {
              setMenu("menu--info");
            }}
          >
            Info
          </button>
          <button
            className={`button ${menu === "menu--controls" ? "active" : ""}`}
            onClick={(e) => {
              setMenu("menu--controls");
            }}
          >
            Controls
          </button>
          <button
            className={`button minimise ${
              menu === "menu--minimise" ? "active" : ""
            }`}
            onClick={(e) => {
              setMenu("menu--minimise");
            }}
          >
            <MinimiseArrow />
          </button>
        </div>
        <div className={`footer-menu ${menu}`}>
          <Intro />
          <div className="bottom-slider controls">
            <Manipulators controls={controls} setControls={setControls} />
            <ViewSelection
              setControls={setControls}
              controls={controls}
              objects={objects}
              cameraController={cameraController}
            />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
