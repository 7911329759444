import { Html } from "@react-three/drei";
import { useEffect, useRef } from "react";

const au = 149.6;

export const PopUps = ({ object, camera, nav }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current && camera === object.name)
      ref.current.classList.add("pop-up-visible");
    else if (ref.current) {
      ref.current.classList.remove("pop-up-visible");
    }
  }, [camera, object, ref]);

  return (
    <group position={[]}>
      <Html wrapperClass="hidden" portal={{ current: nav.current }}>
        <div className="container" ref={ref}>
          <div>
            <h3>{object.name}</h3>
            <p>
              Year: {Math.abs(parseFloat(object.rate.toFixed(2)))} &nbsp;
              <small>
                <em>(Earth years)</em>
              </small>
            </p>
            <p>
              Distance from Sun: {parseFloat((object.distance / au).toFixed(2))}
              &nbsp;
              <small>
                <em>au</em>
              </small>
            </p>
            <p>
              Gravity: {(object.gravity / 9.8).toFixed(2)} &nbsp;
              <small>
                <em>(Earth gravity(g))</em>
              </small>
            </p>
            {object.radius && (
              <p>
                Width: {(object.radius / 0.006371).toFixed(2)} &nbsp;
                <small>
                  <em>(x Earth)</em>
                </small>
              </p>
            )}
            {object.temperature && (
              <p>
                Temperature: {object.temperature}
                <small>
                  <em>°C</em>
                </small>
              </p>
            )}
          </div>
        </div>
      </Html>
    </group>
  );
};
