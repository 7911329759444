import { Html } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useEffect, useMemo, useRef } from "react";
import { MathUtils } from "three";

export const Asteroids = (props) => {
  const points = useRef();
  const count = 2000;

  const { name, rate, distance, thickness } = props.object;
  const { GLOBAL_SCALE_MULTIPLIER, controls } = props;

  // Generate our positions attributes array
  const particlesPosition = useMemo(() => {
    const positions = new Float32Array(count * 3);

    for (let i = 0; i < count; i++) {
      const theta = MathUtils.randFloatSpread(360);

      let x =
        MathUtils.randFloat(
          distance * GLOBAL_SCALE_MULTIPLIER,
          thickness * GLOBAL_SCALE_MULTIPLIER
        ) * Math.sin(theta);
      let y =
        MathUtils.randFloat(
          distance * GLOBAL_SCALE_MULTIPLIER,
          thickness * GLOBAL_SCALE_MULTIPLIER
        ) * Math.cos(theta);
      let z = MathUtils.randFloat(-thickness / 100, thickness / 100);
      positions.set([x, y, z], i * 3);
    }

    return positions;
  }, [distance, GLOBAL_SCALE_MULTIPLIER, thickness]);

  useEffect(() => {
    if (props.controls.camera === name) {
      // props.cameraController.current.setPosition(14, 22, 12, true);

      props.cameraController.current.setLookAt(
        10,
        10 + thickness,
        10,
        (distance / 2) * GLOBAL_SCALE_MULTIPLIER - thickness,
        10,
        (distance / 2) * GLOBAL_SCALE_MULTIPLIER - thickness,
        true
      );
    }
  }, [
    GLOBAL_SCALE_MULTIPLIER,
    distance,
    name,
    props.cameraController,
    props.controls.camera,
    thickness,
  ]);

  useFrame(() => {
    points.current.rotation.z += (1 / rate) * 0.001;
  });

  return (
    <group {...props}>
      {props.controls.labels && (
        <Html
          position={[
            (distance / 2) * GLOBAL_SCALE_MULTIPLIER - thickness,
            10,
            (distance / 2) * GLOBAL_SCALE_MULTIPLIER - thickness,
          ]}
        >
          <p>{name}</p>
        </Html>
      )}
      <points ref={points} rotation={[Math.PI / 2, 0, 0]}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={particlesPosition.length / 3}
            array={particlesPosition}
            itemSize={3}
          />
        </bufferGeometry>
        <pointsMaterial
          color="#F0E1D8"
          size={
            name === "asteroid belt"
              ? 0.15 * controls.scale
              : 0.85 * controls.scale
          }
          sizeAttenuation
        />
      </points>
    </group>
  );
};
