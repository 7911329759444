export const objects = [
  {
    name: "mercury",
    distance: 36,
    eccentricity: 0.205,
    rotation: 1407.6,
    obliquity: 0.034,
    rate: 0.241,
    offset: 0.0,
    radius: 0.0024397,
    inclination: 7.004,
    texture: "2k_mercury.jpg",
    gravity: 3.7,
    temperature: 167,
    moons: 0,
  },
  {
    name: "venus",
    distance: 108.48,
    eccentricity: 0.006,
    rotation: -5832.5,
    obliquity: 177.4,
    rate: -0.615,
    offset: 0.605,
    radius: 0.0060518,
    inclination: 3.395,
    texture: "2k_venus_atmosphere.jpg",
    gravity: 8.9,
    temperature: 464,
    moons: 0,
  },
  {
    name: "earth",
    distance: 149.6,
    eccentricity: 0.016,
    rate: 1,
    rotation: 24.6,
    obliquity: 23.4,
    offset: 0.0,
    radius: 0.006371,
    inclination: 0,
    texture: "2k_earth_daymap.jpg",
    gravity: 9.8,
    temperature: 15,
    moons: 1,
    moon_texture: "2k_moon.jpg",
    moon_radius: 0.001738,
    moon_distance: 0.3844,
    moon_inclination: 5.145,
    moon_eccentricity: 0.0549,
    moon_rate: 0.271,
  },
  {
    name: "mars",
    distance: 228,
    eccentricity: 0.0934,
    rotation: 23.9,
    obliquity: 25.2,
    rate: 2.59897,
    offset: 0.73,
    radius: 0.0033895,
    inclination: 1.848,
    texture: "2k_mars.jpg",
    gravity: 3.7,
    temperature: -65,
    moons: 2,
  },
  {
    name: "jupiter",
    distance: 778,
    eccentricity: 0.0487,
    rotation: 9.9,
    obliquity: 3.1,
    rate: 11.8,
    offset: 0.53,
    radius: 0.071492,
    inclination: 1.304,
    texture: "2k_jupiter.jpg",
    gravity: 23.1,
    temperature: -110,
    moons: 92,
  },
  {
    name: "saturn",
    distance: 1459,
    eccentricity: 0.052,
    rotation: 10.7,
    obliquity: 26.7,
    rate: 29.4,
    offset: 3.3,
    radius: 0.060268,
    inclination: 2.486,
    texture: "2k_saturn.jpg",
    ring: true,
    ring_start: 0.0672,
    ring_end: 0.05 + 0.0672,
    ring_texture: "saturn-rings.png",
    gravity: 9,
    temperature: -140,
    moons: 83,
  },
  {
    name: "uranus",
    distance: 2867,
    eccentricity: 0.0469,
    rotation: -17.2,
    obliquity: 97.8,
    rate: 84,
    offset: 2.12,
    radius: 0.025559,
    inclination: 0.77,
    texture: "2k_uranus.jpg",
    ring: true,
    ring_start: 0.0409,
    ring_end: 0.062932,
    ring_texture: "uranus-rings.png",
    gravity: 8.7,
    temperature: -195,
    moons: 27,
  },
  {
    name: "neptune",
    distance: 4514.9,
    eccentricity: 0.0097,
    rotation: 16.1,
    obliquity: 28.3,
    rate: 164.8,
    offset: 4.13,
    radius: 0.024764,
    inclination: 1.77,
    texture: "2k_neptune.jpg",
    ring: true,
    ring_start: 0.0409,
    ring_end: 0.062932,
    ring_texture: "neptune-rings.png",
    gravity: 11.0,
    temperature: -200,
    moons: 14,
  },
  {
    name: "pluto",
    distance: 5869.6,
    eccentricity: 0.2444,
    rotation: -153.3,
    obliquity: 122.5,
    rate: 248,
    offset: 7.53,
    radius: 0.001188,
    inclination: 17.16,
    texture: "2k_pluto.jpeg",
    gravity: 0.7,
    temperature: -225,
    moons: 5,
  },
  {
    name: "asteroid belt",
    rate: 6,
    distance: 329.1,
    thickness: 478.2,
    gravity: 0,
    temperature: null,
    moons: 0,
  },
  {
    name: "kuiper belt",
    rate: 190,
    distance: 4518,
    thickness: 5478.2,
    gravity: 0,
    temperature: null,
    moons: 0,
  },
];
